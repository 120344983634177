import React from "react";

import Toolbar from "@mui/material/Toolbar";
import { AppBar, Button, CardMedia, Stack } from "@mui/material";
import { styles } from "./styles";
import { NAV_BAR_BUTTON } from "utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

import withResponsive from "components/WithResponsive";

function Navbar(props) {

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPage = (route) => {
    navigate(`${route}`);
  }


  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Stack direction='row' justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <CardMedia
              onClick={() => {
                navigateToPage('');
              }}
              component="img"
              sx={styles.logo(props.responsiveInfo.isMobile)}
              image={require("../../assets/images/verko_logo_text.png")}
            />
            <Stack direction='row' >
              {NAV_BAR_BUTTON.map((item, index) =>
                <Button
                  key={item.name}
                  sx={styles.buttons(location.pathname === item.route, props.responsiveInfo.isMobile)}
                  onClick={() => {
                    navigateToPage(item.route);
                  }}
                  color={location.pathname === item.route ? 'primary' : 'inherit'}>
                  {item.name}
                </Button>)}
            </Stack>

            {props.responsiveInfo.isMobile ? null : <a
              href="https://play.google.com/store/apps/details?id=in.verkoApp"
              target="_blank" rel="noreferrer"
            >
              <CardMedia
                component="img"
                sx={styles.playIcon}
                image="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withResponsive(Navbar);
